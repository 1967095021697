<template>
  <div class="massorder titlesTop">
    <headTitle title="发布心愿"></headTitle>
    <div class="main">
      <div class="input_box">
        <div class="head_font">
          <span>*</span>
          <span>许愿人</span>
        </div>
        <div class="input_div">
          <span>{{memberName}}</span>
        </div>
      </div>
      <div class="input_box">
        <div class="head_font">
          <span>*</span>
          <span>手机号</span>
        </div>
        <div class="input_div">
          <span>{{phone}}</span>
        </div>
      </div>
      <div class="input_box">
        <div class="head_font">
          <span>*</span>
          <span>所在乡镇</span>
        </div>
        <div class="input_div" @click="tree_show = true">
          <span
            :class="{ 'font_color': isSelectedOfTree, textControl: true }"
            style="font-size:15px"
          >{{regionName}}</span>
          <img src="../../assets/images/xiajt.png" alt />
        </div>
      </div>
      <div class="input_box">
        <div class="head_font">
          <span>*</span>
          <span>心愿类型</span>
        </div>
        <div class="input_div" @click="bottom_window = true">
          <span
            :class="{ 'font_color': isSelectedOfWish, textControl: true }"
            style="font-size:15px"
          >{{wishClass}}</span>
          <img src="../../assets/images/xiajt.png" alt />
        </div>
      </div>
      <div class="input_box">
        <div class="head_font">
          <span>*</span>
          <span>申请会员类型</span>
        </div>
        <div class="input_div" @click="member_window = true">
          <span
            :class="{ 'font_color': isSelectedOfMember, textControl: true }"
            style="font-size:15px"
          >{{personClass}}</span>
          <img src="../../assets/images/xiajt.png" alt />
        </div>
      </div>
      <div class="input_box">
        <div class="head_font">
          <span>*</span>
          <span>心愿描述</span>
        </div>
        <textarea
          v-model="wishForm.description"
          rows="5"
          style="font-size:16px"
          placeholder="请输入心愿描述"
        ></textarea>
        <div class="bottom_btn" @click="summitWish">提交心愿</div>
      </div>
    </div>

    <van-action-sheet
      v-model="bottom_window"
      :actions="wishClassList"
      cancel-text="取消"
      close-on-click-action
      @select="onSelectOfwish"
      @cancel="onCancel"
    />
    <van-action-sheet
      v-model="member_window"
      :actions="memberClassList"
      cancel-text="取消"
      close-on-click-action
      @select="onSelectOfMember"
      @cancel="onCancel"
    />
    <van-action-sheet v-model="tree_show">
      <div class="tree_box">
        <tree :list="list" :treetype="1"></tree>
      </div>
    </van-action-sheet>
  </div>
</template>
<script>
import wishApi from "@/api/wish";
import peopleOrderApi from "@/api/peopleOrder";
import { Toast } from "vant";
import Tree from "../massorder/tree";
import Bus from "../../bus";
Toast.setDefaultOptions({ duration: 2500 });
export default {
  data() {
    return {
      wishForm: {
        description: "",
        memberId: "",
        memberName: "",
        regionId: "",
        regionName: "",
        wishTypeName: "",
        memberTypeId: "",
      },
      phone: "",
      memberType: "",
      params: {
        appName: this.appName,
      },
      bottom_window: false,
      member_window: false,
      tree_show: false, // 展示树形列表的面板
      wishClassList: [], // 心愿类型
      memberClassList: [], // 申请人类型
      list: [], // 区域列表
      memberName: "",
      regionName: "请选择乡镇",
      wishClass: "请选择心愿类型",
      personClass: "请选择会员类型",
      userInfo: null, // 用户信息
      isSelectedOfTree: false,
      isSelectedOfWish: false,
      isSelectedOfMember: false,
    };
  },
  components: {
    Tree,
  },
  created() {
    // 用户信息
    this.obtainInformation();  // 拿用户信息

    // 选择的区域乡镇
    Bus.$on('regionTree', data => {
      // console.log(data);
      this.wishForm.regionName = data.name;
      this.wishForm.regionId = data.keyId;
      this.regionName = data.name;
      this.isSelectedOfTree = true;
      this.tree_show = false;
    });
  },
  mounted() {
    this.getWishClass();
    this.getMemberClass();
    this.getRegionData();
  },
  methods: {
    // 拿用户信息
    obtainInformation() {
      if (window.localStorage.personalInformation) {
        var data = JSON.parse(window.localStorage.personalInformation);
        this.phone = data.phone;
        this.memberName = data.nickname;
        this.wishForm.memberName = data.nickname;
        this.wishForm.memberId = data.id;
      }
    },
    // 获取心愿类型
    getWishClass() {
      let params = { // app名字
        appName: this.appName
      }
      wishApi.wishType(params).then(res => {
        console.log(res);
        if (res.success) {
          this.wishClassList = res.data;
        } else {
          Toast(`${res.message}`);
        }
      })
    },
    // 获取会员类型
    getMemberClass() {
      let params = { // app名字
        appName: this.appName
      }
      wishApi.memberType(params).then(res => {
        console.log("申请类型", res);
        if (res.success) {
          this.memberClassList = res.data;
        } else {
          Toast(`${res.message}`);
        }
      })
    },
    // 获取乡镇部门
    getRegionData() {
      let params = {
        appName: this.appName
      }
      peopleOrderApi.regiontree(params).then(res => {
        if (res.success) {
          this.list = res.data
        } else {
          Toast(`${res.message}`);
        }
      })
    },
    onSelectOfwish(item) {
      // console.log(item.name);
      this.wishForm.wishTypeName = item.name;
      this.wishClass = item.name;
      this.isSelectedOfWish = true;
    },
    onSelectOfMember(item) {
      this.memberType = item.name;
      this.personClass = item.name;
      this.wishForm.memberTypeId = item.id;
      this.isSelectedOfMember = true;
    },
    onCancel() {
      Toast("取消");
    },
    // 提交心愿
    summitWish() {
      const flag = this.checkForm();
      if (!flag) {
        return;
      }
      wishApi.wishAdd(this.wishForm).then(res => {
        // console.log(res);
        if (res.success) {
          Toast("发布成功,请等待管理员审核！");
          setTimeout(() => {
            this.$router.go(-1);
          }, 1000);
        } else {
          Toast("发布失败");
        }
      })
    },
    // 校验表单
    checkForm() {
      if (!this.wishForm.regionName) {
        Toast("请选择所在乡镇");
        return false;
      } else if (!this.wishForm.wishTypeName) {
        Toast("请选择心愿类型");
        return false;
      } else if (!this.wishForm.memberTypeId) {
        Toast("请选择会员类型");
        return false;
      } else if (!this.wishForm.description) {
        Toast("请输入心愿描述");
        return false;
      } else {
        return true;
      }
    },
    // 提交心愿成功后，生成新的表单，清除老数据
    createNewForm() {
      this.wishForm = {
        description: "",
        memberId: "",
        memberName: "",
        regionId: "",
        regionName: "",
        wishTypeName: "",
        memberTypeId: "",
      }
      this.obtainInformation(); // 拿用户信息
      this.regionName = '';
      this.wishClass = '';
      this.personClass = '';
      this.memberType = "";
    }
  },
};
</script>

<style>
[class*="van-hairline"]::after {
  border: none;
}
textarea::-webkit-input-placeholder {
  font-size: 15px;
}
</style>
<style lang="less" scoped>
.headContent {
  width: 100%;
  height: 50px;
  background: #1377e2;
  color: #fff;
  padding: 0px 40px;
  padding-right: 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0px;
  font-size: 16px;
  z-index: 9999;
  p {
    width: 100%;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  i {
    position: absolute;
    left: 15px;
    font-size: 20px;
  }
}
.main {
  overflow: auto;
  // -webkit-overflow-scrolling: touch;
  // -webkit-box-sizing: border-box;
  // box-sizing: border-box;
  // height: auto;
  position: relative;
  z-index: 1;
  height: 100%;
  // padding-top: 13vw;
  // padding-bottom: 4vw;
}
.input_box {
  padding: 0 5vw;
  margin-top: 4vw;
  .head_font {
    margin-bottom: 2vw;
    span {
      &:nth-child(1) {
        color: red;
        margin-right: 5px;
      }
      &:nth-child(2) {
        font-size: 0.34rem;
      }
    }
  }
  .input_div {
    color: #959595;
    border: #eaeaea solid 1px;
    height: 9vw;
    line-height: 9vw;
    padding-left: 2vw;
    font-size: 14px;
    .font_color {
      color: #000;
    }
    .textControl {
      display: inline-block;
      width: 80vw;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    img {
      width: 6vw;
      float: right;
    }
  }
  textarea::-webkit-input-placeholder {
    /* 修改字体颜色 */
    color: #959595;
    padding: 2vw 0 0 2vw;
  }
  input,
  textarea {
    width: 99%;
    border: #eaeaea 1px solid;
  }
}
.bottom_btn {
  width: 60vw;
  height: 12vw;
  background: #1377e2;
  color: white;
  text-align: center;
  line-height: 12vw;
  font-size: 0.37rem;
  z-index: 99;
  border-radius: 5px;
  margin: 20px auto;
}
.massorder {
  .content {
    margin-top: 20px;
  }
  .line {
    height: 2px;
    width: 100%;
    margin-top: 0.35rem;
  }
  .van-field__body {
    padding: 0 0 0 10px;
  }
}
.tree_box {
  padding: 5vw 0 15vw 3vw;
}
</style>